.unit{
    font-size: 12px;
    color: #fff;
}
.number {
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    color: $white;
    text-transform: uppercase;
    position: relative;

    @media (max-width: 992px) {
        font-size: 25px;
        line-height: 34px;
    }

    &-card {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-box {
        width: 150px;
        height: 150px;
        position: relative;
        background: $secondary;
        margin-bottom: 20px;
        align-items: center;
        justify-content: center;
        padding-top:50px;
        text-align: center;
        
        @media (max-width: 992px) {
            width: 120px;
            height: 120px;
        }

        @media (max-width: 820px) {
            width: 150px;
            height: 150px;
        }
    }

    &-bg {
        position: absolute;
        bottom: 0;
        left: 0;

        img {
            @media (max-width: 992px) {
                width: 70%;
            }
        }
    }

    &-text {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: $dark;
        text-transform: uppercase;

        @media (max-width: 992px) {
            font-size: 12px;
            line-height: 15px;
        }
    }

    &-section {
        @include padding-x;
        padding-block: 140px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: center;
        gap: 42px;

        @media (max-width: 992px) {
            gap: 0;
        }

        @media (max-width: 820px) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 35px;
        }

        @media (max-width: 560px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
        }
    }
}