.footer {
    &-wrap {
        background: $dark;
        padding-inline: 100px;
        position: relative;

        @media (max-width: 820px) {
            padding-inline: 65px;
        }

        @media (max-width: 560px) {
            padding-inline: 40px;
        }
    }

    &-menu {
        @media (max-width: 560px) {
            display: grid;
            row-gap: 15px;
            grid-template-columns: repeat(2, 1fr);
            margin-top: 20px;
        }
    }

    &-logo {
        padding-right: 40px;
        border-right: 1px solid $primary;
        margin-right: 20px;
    }

    &-link {
        padding: 0 20px;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: $white;
        transition: all .3s ease;

        @media (max-width: 820px) {
            padding: 0 15px;
        }

        &:hover {
            color: $secondary;
        }
    }

    &-top {
        padding-top: 53px;
        padding-bottom: 40px;

        @media (max-width: 820px) {
            padding-top: 35px;
            padding-bottom: 25px;
        }

        @media (max-width: 560px) {
            flex-direction: column;
        }
    }

    &-bottom {
        border-top: 1px solid $primary;
        padding-top: 30px;
        padding-bottom: 53px;

        @media (max-width: 820px) {
            padding-top: 30px;
            padding-bottom: 40px;
        }
    }
}

.copyright {
    font-size: 11px;
    line-height: 14px;
    color: rgba($color: $white, $alpha: 0.5);
}

.social {
    &-link {
        padding: 0 9px;
        color: $secondary;
        transition: all .3s ease;

        &:hover {
            color: $primary;
        }
    }

    &-wrap {
        margin-top: 20px;
    }
}