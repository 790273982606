@import url('https://use.typekit.net/ajt6eeb.css%22>');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
    font-family: 'hello-valentina';
    src: url(../font/hello-valentina.ttf);
}


* {
    @include zero-sizing;
    box-sizing: border-box;
}

body {
    @include zero-sizing;
    width: 100vw;
    overflow-x: hidden;
    font-size: 16px;
    line-height: 20px;
    @include outfit;

    @media (max-width: 560px) {
        overflow-x: hidden;
    }
}

h1, h2, h3, h4, h5, h6, p, ul, li, ol, a {
    @include zero-sizing;
}

h1, h2, h3, h4, h5, h6, input, textarea {
    @include secondary-font;
}

a {
    text-decoration: none;
}

.main-body {
    background-image: url(../img/svg-lines.png);
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
}

.padding-x {
    @include padding-x;
}

.text-btn {
    font-size: 20px;
    line-height: 25px;
    color: $secondary;
    text-transform: uppercase;
    cursor: pointer;

    @media (max-width: 1440px) {
        font-size: 18px;
        line-height: 13px;
    }

    @media (max-width: 992px) {
        font-size: 16px;
        line-height: 20px;
    }

    svg {
        margin-left: 10px;
        transform: translateX(0);
        transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &:hover {
        color: $secondary;

        svg {
            transform: translatex(10px);
        }
    }
}

.page-heading {
    font-weight: 500;
    font-size: 34px;
    line-height: 57px;
    text-transform: uppercase;
    color: $dark;

    @media (max-width: 1440px) {
        font-size: 30px;
        line-height: 50px;
    }

    @media (max-width: 1280px) {
        font-size: 25px;
        line-height: 44px;
    }

    @media (max-width: 820px) {
        font-size: 20px;
        line-height: 40px;
    }

    @media (max-width: 560px) {
        font-size: 18px;
        line-height: 32px;
    }

    &-wrap {
        @include padding-x;
        padding-top: 150px;

        @media (max-width: 1440px) {
            padding-top: 120px;
        }

        @media (max-width: 1280px) {
            padding-top: 90px;
        }

        @media (max-width: 820px) {
            padding-top: 70px;
        }
    }
}

.primary-bg {
    padding: 20px;
    background: $primary;
    width: 100%;

    &-heading {
        @include padding-x;
        padding-top: 100px;
        padding-bottom: 40px;

        @media (max-width: 992px) {
            padding-top: 80px;
            padding-bottom: 30px;
        }

        .bg-heading {
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            text-transform: uppercase;
            color: $white;

            @media (max-width: 1366px) {
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
}

.devider {
    height: 1px;
    width: 100%;
    background: $secondary;

    &-wrap {
        position: relative;
        padding-block: 60px;
        @include padding-x;

        @media (max-width: 820px) {
            padding-block: 40px;
        }

        @media (max-width: 560px) {
            padding-block: 30px;
        }
    }
}