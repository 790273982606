@mixin zero-sizing {
    padding: 0;
    margin: 0;
}

@mixin outfit {
    font-family: 'Outfit', sans-serif;
}
@mixin secondary-font {
    font-family: orpheuspro,serif;
}
@mixin cursive-font {
    font-family: 'hello-valentina';
}

@mixin padding-y {
    padding-block: 140px;

    @media (max-width: 1600px) {
        padding-block: 125px;
    }

    @media (max-width: 992px) {
        padding-block: 70px;
    }
}

@mixin padding-bottom {
    padding-bottom: 140px;

    @media (max-width: 1600px) {
        padding-bottom: 125px;
    }

    @media (max-width: 992px) {
        padding-bottom: 70px;
    }
}

@mixin padding-x {
    padding-inline: 375px;

    @media (max-width: 1600px) {
        padding-inline: 325px;
    }

    @media (max-width: 1440px) {
        padding-inline: 245px;
    }

    @media (max-width: 1200px) {
        padding-inline: 145px;
    }

    @media (max-width: 992px) {
        padding-inline: 125px;
    }

    @media (max-width: 820px) {
        padding-inline: 90px;
    }

    @media (max-width: 560px) {
        padding-inline: 30px;
    }
}

@mixin padding-left {
    padding-left: 375px;

    @media (max-width: 1600px) {
        padding-left: 325px;
    }

    @media (max-width: 1440px) {
        padding-left: 245px;
    }

    @media (max-width: 1200px) {
        padding-left: 145px;
    }

    @media (max-width: 992px) {
        padding-left: 125px;
    }

    @media (max-width: 820px) {
        padding-left: 90px;
    }

    @media (max-width: 560px) {
        padding-left: 30px;
    }
}

@mixin form-text {
    font-size: 22px;
    line-height: 28px;

    @media (max-width: 1680px) {
        font-size: 20px;
        line-height: 26px;
    }

    @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 24px;
    }

    @media (max-width: 820px) {
        font-size: 16px;
        line-height: 22px;
    }
}