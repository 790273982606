.banner {
    &-wrap {
        position: relative;
        width: 100%;
        height: 100vh;
        overflow: hidden;

        @media (max-width: 992px) {
            height: 80vh;
        }

        @media (max-width: 560px) {
            height: 50vh;
        }
    }

    &-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1;
        
        @media (max-width: 1600px) {
            width: 100%;
        }

        @media (max-width: 1440px) {
            width: auto;
            height: 100vh;
        }
    }

    &-svg {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;

        @media (max-width: 820px) {
            width: auto;
            height: 65%;
            left: -140px;
        }

        @media (max-width: 560px) {
            height: 100%;
            left: -17%;
        }
    }

    &-content {
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        padding-bottom: 225px;
        height: 100%;

        @media (max-width: 992px) {
            padding-bottom: 40px;
        }
    }

    &-title {
        font-weight: 700;
        font-size: 55px;
        line-height: 68px;
        position: relative;
        padding-top: 60px;
        color: $white;
        padding: 0px 90px;

        @media (max-width: 1600px) {
            padding-top: 0px;
            font-size: 28px;
            line-height: 58px;
        }

        @media (max-width: 992px) {
            padding-top: 30px;
            font-size: 30px;
            line-height: 46px;
        }

        @media (max-width: 560px) {
            font-size: 22px;
            line-height: 32px;
            padding: 0 50px;
        }

        &::before {
            position: absolute;
            top: 0;
            width: 115px;
            height: 1px;
            content: '';
            background: $primary;
            left: calc(50% - 57px);
        }
    }

    &-text {
        font-size: 65px;
        line-height: 76px;
        @include cursive-font;
        color: $secondary;

        @media (max-width: 992px) {
            font-size: 40px;
            line-height: 68px;
        }

        @media (max-width: 560px) {
            font-size: 30px;
            line-height: 55px;
        }
    }

    &-logo {
        margin-bottom: 60px;

        @media (max-width: 1600px) {
            margin-bottom: 50px;
            width: 350px;
        }

        @media (max-width: 992px) {
            margin-bottom: 30px;
            width: 225px;
        }
    }
}