.news{
    position: relative;
    .news-filter-bar {
        padding-inline: 500px;
        background: $gray-200;
        display: flex;
        align-items: center;
        margin-top: 50px;
    
        @media (max-width: 1880px) {
            padding-inline: 450px;
        }
    
        @media (max-width: 1680px) {
            padding-inline: 425px;
        }
    
        @media (max-width: 1440px) {
            @include padding-x;
        }
    
        @media (max-width: 820px) {
            flex-direction: column;
            background: none;
            margin-top: 100px;
        }
    
        @media (max-width: 560px) {
            margin-top: 70px;
        }
    }
    .detail-tit{
        margin: 0rem 6rem 2rem 2rem;
        .detail-title-wrap {
            text-align: left;
            &::before {
            width: 100%;
        }
        .page-heading-wrap{
            padding-inline: 0;
            padding: 0;
            .page-heading{
                font-size: 25px;
                text-transform : inherit
            }
        }
      }
    }

    .highlight-text{
        p{
            font-size: 12px;
            margin-bottom: 0;
        }
        .date-color{
            color: $secondary;
        }
    }
    .read-more{
        margin-top: 1.5rem;
        margin-left: 2rem;
        a{
            letter-spacing: 4px;
            color: $primary;
            font-weight: 500;
            font-size: 15px;
        }
    }

    .years{
        .year-title{
            font-weight: bold;
        }
        .small-info-text{
            font-size: 15px;
            margin-top: 1.5rem;
        }
    }
    
}