.header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 33;
    background: $dark;
}
.logo-wrap {
    padding: 33px 40px;

    @media (max-width: 1600px) {
        padding: 25px 40px;
    }
}
.logo-img {
    width: 200px;
}
.menu {
    &-item {
        @media (max-width: 1024px) {
            width: 100%;
            padding: 25px 0;
        }
    }

    &-link {
        padding: 48px 60px;
        border-left: 1px solid $primary;
        font-size: 12px;
        text-transform: uppercase;
        color: $white;
        cursor: pointer;
        transition: all .3s ease;

        // &:last-of-type {
        //     border-right: 1px solid $primary;
        // }

        @media (max-width: 1600px) {
            padding: 39px 40px;
        }

        @media (max-width: 1024px) {
            border: none;
            font-size: 22px;
            line-height: 35px;
            text-align: start;
            padding: 0;
            width: 100%;
        }

        &:hover {
            color: $secondary;
        }
    }

    &-wrap {
        height: 100%;
    }
}

.desk-menu {
    height: 100%;

    @media (max-width: 1024px) {
        position: absolute;
        top: 0;
        left: 100%;
        width: 50%;
        height: 100vh;
        flex-direction: column;
        background: rgba($color: $dark, $alpha: 0.9);
        padding: 200px 60px;
        justify-content: center;
        z-index: 30;
        transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    @media (max-width: 560px) {
        width: 100%;
    }

    &.open {
        @media (max-width: 1024px) {
            left: 50%;
        }

        @media (max-width: 560px) {
            left: 0;
        }
    }
}

.sub {
    &-wrap {
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        display: none;
        transition: all .3s ease;
        background: rgba($color: $dark, $alpha: 0.8);
        text-align: center;

        .menu {
            &-link {
                border-left: none;
                font-size: 12px;
                padding: 0;

                &:hover {
                    color: $white;
                }
            }

            &-item {
                padding: 20px 15px;
                background: none;
                transition: all .3s ease;
                border-top: 1px solid $secondary;

                &:first-of-type {
                    border: none;
                }

                &:hover {
                    background: $primary;
                }
            }
        }
    }

    &-icon {
        transition: all .3s ease;
        transform: rotate(0);
    }
}

.menu-bars {
    position: relative;
    z-index: 33;
    cursor: pointer;
    padding: 35px 60px;

    @media (max-width: 1600px) {
        padding: 25px 45px;
    }

    .line {
        width: 35px;
        height: 2px;
        border-radius: 6px;
        background: $white;
        transform: rotate(0);
        transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
        margin: 8px 0;
        display: block;
        position: relative;

        @media (max-width: 1024px) {
            display: block;
        }

        @media (max-width: 560px) {
            width: 35px;
            margin: 7px 0;
        }
    }

    &.open {
        .line {
            &:first-of-type {
                transform: rotate(45deg);
                top: 10px;
                left: 0;

                @media (max-width: 560px) {
                    top: 10px;
                }
            }
            &:nth-of-type(2) {
                display: none;
            }
            &:last-of-type {
                transform: rotate(-45deg);
            }
        }
    }
}

.sidebar-wrap {
    width: 50%;
    position: fixed;
    z-index: 22;
    top: 0;
    right: -50%;
    height: 100vh;
    transition: all .3s cubic-bezier(0.075, 0.82, 0.165, 1);
    background: rgba(1, 25, 52, 0.8);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(6.9px);
    -webkit-backdrop-filter: blur(6.9px);

    @media (max-width: 560px) {
        width: 100%;
        right: -100%;
    }

    &.open {
        right: 0;
    }
}

.dropdown-wrap {
    border-left: 1px solid $primary;
    height: 100%;
    padding: 48px 60px;
    color: $white;
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    position: relative;

    @media (max-width: 1880px) {
        padding: 46px 50px;
    }

    @media (max-width: 1600px) {
        padding: 37px 40px;
    }

    @media (max-width: 1024px) {
        padding: 0;
    }

    &:hover {
        .sub {
            &-wrap {
                display: block;
            }

            &-icon {
                transform: rotate(180deg);
            }
        }
    }
}

.sidebar {
    &-menu-items {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: 100%;
        padding: 55px 55px 55px 150px;
        color: $white;
        flex-direction: column;

        @media (max-width: 1024px) {
            padding-left: 85px;
        }

        @media (max-width: 820px) {
            padding: 40px 40px 40px 55px;
        }

        @media (max-width: 560px) {
            align-items: center;
        }
    }

    &-link {
        color: $white;
        font-size: 40px;
        line-height: 58px;
        margin-top: 30px;
        transition: all .3s ease;
        @include secondary-font;

        @media (max-width: 1024px) {
            font-size: 28px;
            line-height: 38px;
            margin-top: 20px;
        }

        @media (max-width: 820px) {
            font-size: 22px;
            line-height: 32px;
            margin-top: 15px;
        }

        @media (max-width: 560px) {
            text-align: center;
            margin-top: 30px;
        }
        
        &:hover {
            color: $secondary;
        }
    }
}

.lap-menu {
    display: flex;
    flex-direction: column;

    @media (max-width: 1024px) {
        display: none;
    }

    .form-control {
        padding: 18px 16px;
        font-size: 18px;
        line-height: 22px;
        background: rgba($color: $dark, $alpha: 0.8);
        color: $white;

        &::placeholder {
            color: $gray-300;
        }
    }

    .submit-btn {
        padding: 18px 50px;
        font-size: 18px;
        line-height: 22px;
    }
}

.mobile-menu {
    display: none;
    
    @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
    }
}

.has-sub {
    color: $white;

    &:hover {
        color: $white;
    }
}