.about {
    &-owner {
        padding-top: 150px;
        padding-bottom: 140px;

        @media (max-width: 1440px) {
            padding-top: 120px;
            padding-bottom: 110px;
        }

        @media (max-width: 1280px) {
            padding-top: 90px;
            padding-bottom: 80px;
        }

        @media (max-width: 820px) {
            padding-top: 70px;
            padding-bottom: 60px;
        }
    }

    &-heading {
        background: $gray-200;
        padding: 43px;
        font-size: 32px;
        line-height: 53px;
        text-transform: uppercase;

        @media (max-width: 1680px) {
            padding: 39px;
            font-size: 28px;
            line-height: 49px;
        }

        @media (max-width: 1366px) {
            padding: 34px;
            font-size: 25px;
            line-height: 45px;
        }

        @media (max-width: 820px) {
            padding: 25px;
            font-size: 20px;
            line-height: 40px;
        }

        @media (max-width: 560px) {
            font-size: 16px;
            line-height: 26px;
        }

        &-wrap {
            @include padding-x;
        }
    }

    &-section {
        &-title {
            font-size: 20px;
            line-height: 25px;
            color: $white;
            margin-bottom: 15px;

            @media (max-width: 1280px) {
                font-size: 18px;
                line-height: 23px;
                margin-bottom: 13px;
            }
        }

        &-desc {
            font-size: 16px;
            line-height: 26px;
            color: $white;

            @media (max-width: 1280px) {
                font-size: 14px;
                line-height: 22px;            
            }
        }
    }

    &-inner-section {
        @include padding-x;
        @include padding-bottom;
    }

    &-info {
        &-wrap {
            display: flex;
            column-gap: 50px;

            @media (max-width: 560px) {
                row-gap: 30px;
                flex-wrap: wrap;
            }
        }

        &-content {
            width: calc(80% - 50px);

            @media (max-width: 560px) {
                width: 100%;
            }
        }
    }
}

.highlight-text {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
    color: $primary;

    @media (max-width: 1680px) {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 15px;
    }

    @media (max-width: 1366px) {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 10px;
    }

    @media (max-width: 1280px) {
        font-size: 16px;
        line-height: 26px;
    }
}

.desc-text {
    font-size: 24px;
    line-height: 32px;
    opacity: 0.6;
    width: 93%;

    @media (max-width: 1680px) {
        font-size: 20px;
        line-height: 30px;
        width: 98%;
    }

    @media (max-width: 1366px) {
        font-size: 18px;
        line-height: 28px;
    }

    @media (max-width: 1280px) {
        font-size: 16px;
        line-height: 26px;
    }
}

.earth-img {
    width: 20%;
    height: 212px;
    overflow: hidden;

    @media (max-width: 560px) {
        width: 100%;
        height: auto;
    }

    img {
        width: 100%;
    }
}

.inner-about-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 64px;
    margin-bottom: 50px;
    text-transform: uppercase;

    @media (max-width: 1680px) {
        font-size: 42px;
        line-height: 60px;
        margin-bottom: 45px;
    }

    @media (max-width: 1366px) {
        font-size: 36px;
        line-height: 55px;
    }

    @media (max-width: 1280px) {
        font-size: 32px;
        line-height: 50px;
    }

    @media (max-width: 820px) {
        font-size: 26px;
        line-height: 40px;
    }
}

.owner {
    &-detail {
        background: url(../img/inner-section-banner.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        padding-block: 50px;
        @include padding-x;

        @media (max-width: 820px) {
            background-position: right;
        }
    }

    &-img-wrap {
        display: flex;
        align-items: center;
        column-gap: 50px;

        @media (max-width: 1280px) {
            column-gap: 30px;
        }

        @media (max-width: 560px) {
            column-gap: 15px;
        }

        .team {
            &-about {
                transform: translateY(0);

                @media (max-width: 1680px) {
                    padding: 20px;
                }
            }

            &-box {
                width: 250px;
                height: 300px;

                @media (max-width: 1680px) {
                    width: 210px;
                    height: 245px;
                }

                @media (max-width: 1280px) {
                    width: 190px;
                    height: 220px;
                }

                @media (max-width: 820px) {
                    width: 215px;
                    height: 260px;
                }

                @media (max-width: 560px) {
                    width: 175px;
                    height: 210px;
                }

                img {
                    width: 100%;
                    height: auto;
                }
            }

            &-name {
                font-size: 20px;
                line-height: 25px;

                @media (max-width: 1680px) {
                    font-size: 18px;
                    line-height: 23px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }
            }

            &-position {
                font-size: 14px;
                line-height: 17px;
            }
        }
    }
}

.mission {
    font-size: 20px;
    line-height: 30px;
    padding-left: 40px;
    position: relative;

    @media (max-width: 1280px) {
        font-size: 18px;
        line-height: 28px;
        padding-left: 32px;
    }

    @media (max-width: 560px) {
        padding-left: 0;
        padding-top: 20px;
    }

    &::before {
        position: absolute;
        content: '';
        height: 100%;
        width: 1px;
        background-color: $secondary;
        left: 0;
        top: 0;

        @media (max-width: 560px) {
            height: 1px;
            width: 100%;
        }
    }

    &-wrap {
        @include padding-x;
    }

    &-content {
        background: $gray-200;
        padding: 93px 225px;

        @media (max-width: 1800px) {
            padding: 87px 175px;
        }

        @media (max-width: 1680px) {
            padding: 75px 160px;
        }

        @media (max-width: 1280px) {
            padding: 75px 115px;
        }

        @media (max-width: 820px) {
            padding: 55px 65px;
        }

        @media (max-width: 560px) {
            padding: 35px 45px;
        }
    }
}