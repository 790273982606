.team-section-wrap {
    @include padding-x;
    padding-top: 100px;
    padding-bottom: 100px;
}

.team-inner {
    &-img {
        width: 100%;
        overflow: hidden;
        height: 290px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 20px;

        @media (max-width: 1680px) {
            height: 250px;
        }

        @media (max-width: 1280px) {
            height: 280px;
        }

        @media (max-width: 1200px) {
            height: 320px;
        }

        @media (max-width: 1024px) {
            height: 280px;
        }

        @media (max-width: 820px) {
            height: 250px;
            margin-bottom: 15px;
        }

        @media (max-width: 768px) {
            height: 220px;
        }

        img {
            height: 100%;
            min-width: 100%;
        }
    }

    &-name {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: $dark;
        margin-bottom: 10px;

        @media (max-width: 1800px) {
            font-size: 22px;
            line-height: 28px;
            margin-bottom: 5px;
        }

        @media (max-width: 1680px) {
            font-size: 20px;
            line-height: 26px;
        }

        @media (max-width: 820px) {
            font-size: 18px;
            line-height: 24px;
        }

        @media (max-width: 768px) {
            font-size: 17px;
            line-height: 23px;
        }

        @media (max-width: 560px) {
            font-size: 16px;
            line-height: 23px;
        }
    }

    &-position {
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
        color: $dark;

        @media (max-width: 820px) {
            font-size: 13px;
            line-height: 15px;
        }
    }
}

.team-filter-bar {
    padding-inline: 500px;
    background: $gray-200;
    display: flex;
    align-items: center;
    margin-top: 150px;

    @media (max-width: 1880px) {
        padding-inline: 450px;
    }

    @media (max-width: 1680px) {
        padding-inline: 425px;
    }

    @media (max-width: 1440px) {
        @include padding-x;
    }

    @media (max-width: 820px) {
        flex-direction: column;
        background: none;
        margin-top: 100px;
    }

    @media (max-width: 560px) {
        margin-top: 70px;
    }
}

.filter {
    &-title {
        font-weight: 700;
        font-size: 11px;
        line-height: 14px;
        text-transform: uppercase;
        margin-right: 60px;
        padding-block: 22px;
    }

    &-btn {
        padding-inline: 50px;
        border: none;
        border-radius: 0;
        padding-block: 22px;
        position: relative;
        text-transform: uppercase;
        color: rgba($color: $dark, $alpha: 0.5);
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;

        @media (max-width: 1680px) {
            padding-inline: 45px;
            font-size: 11px;
        }

        @media (max-width: 820px) {
            background: $gray-200;
            padding-inline: 40px;
        }

        &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 17px;
            top: calc(50% - 8.5px);
            left: 0;
            background: $secondary;

            @media (max-width: 820px) {
                content: none;
            }
        }

        &.active {
            background: $secondary;
            color: $white;
        }
    }
}

.btn-wrap {
    @media (max-width: 560px) {
        flex-wrap: wrap;
        gap: 10px;
    }
}