.investor-form {
    @include padding-x;
    padding-block: 100px;
}

.form {
    &-wrap {
        background: $gray-200;
        padding: 60px 45px;

        @media (max-width: 1024px) {
            padding: 50px 35px;
        }
    }

    &-label {
        margin-bottom: 10px;
        @include form-text;
    }

    &-control {
        border-radius: 0;
        border: none;
        outline: none;
        box-shadow: none;
        background: $white;
        padding: 24px 20px;
        @include form-text;

        @media (max-width: 1024px) {
            padding: 20px 16px;
        }

        @media (max-width: 768px) {
            padding: 15px 12px;
        }

        &:focus {
            box-shadow: none;
        }

        &::placeholder {
            opacity: 0.35;
        }
    }
}

.submit-btn {
    padding: 24px 55px;
    background: $primary;
    @include form-text;
    color: $white;
    border-radius: 0;

    @media (max-width: 1024px) {
        padding: 20px 51px;
    }

    @media (max-width: 768px) {
        padding: 15px 45px;
    }

    &:hover {
        background: $dark;
        color: $white;
    }
}