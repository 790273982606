.inner-banner {
    height: 47vh;
    @include padding-x;
    padding-bottom: 135px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    background-image: url(../img/inner-banner.png);
    width: 100vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (max-width: 1024px) {
        height: 40vh;
        padding-bottom: 105px;
    }

    @media (max-width: 820px) {
        height: 32vh;
        padding-bottom: 75px;
    }

    &-title {
        width: 50%;
        font-weight: 700;
        font-size: 55px;
        line-height: 68px;
        color: $dark;
        text-transform: uppercase;

        @media (max-width: 1680px) {
            font-size: 48px;
            line-height: 52px;
        }

        @media (max-width: 1280px) {
            font-size: 42px;
            line-height: 47px;
        }

        @media (max-width: 1024px) {
            font-size: 38px;
            line-height: 42px;
        }

        @media (max-width: 820px) {
            font-size: 34px;
            line-height: 37px;
        }
    }
}
.esg-banner{
    background-image: url(../img/esg-banner.png);
}