.info {
    &-section {
        padding: 111px 154px;
        background: url(../img/info.png);
        background-position: center left;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        height: 360px;

        @media (max-width: 560px) {
            height: 320px;
            padding: 60px 40px;
        }
    }

    &-text {
        font-style: italic;
        position: relative;
        width: 50%;
        float: right;
        font-size: 32px;
        line-height: 46px;
        color: $white;

        @media (max-width: 1440px) {
            width: 57%;
            font-size: 26px;
            line-height: 46px;
        }

        @media (max-width: 992px) {
            width: 100%;
        }

        @media (max-width: 820px) {
            font-size: 22px;
            line-height: 40px;
        }
    }
}

.shape {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
}