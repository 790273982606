.portfolio-partners{
    .about-section{
        .about-wrap{
            img{
                display: none;
            }
            .about-content{
                .about-title{
                    width: 100%;
                }
            }
        }
    }
}

.portfolio-wrap {
    @include padding-x;
    padding-bottom: 200px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-content: center;

    @media (max-width: 560px) {
        grid-template-columns: 225px;
    }
}

.portfolio {
    &-box {
        height: 200px;
        border: 1px solid $dark;
        transition: all .3s ease;

        @media (max-width: 1440px) {
            height: 170px;
        }

        @media (max-width: 1024px) {
            height: 100px;
        }

        @media (max-width: 560px) {
            margin-bottom: 5px;
        }
    }

    &-img {
        height: 60px;
        transition: all .3s ease;

        @media (max-width: 1440px) {
            height: 45px;
        }

        @media (max-width: 1024px) {
            height: 25px;
        }
    }
}